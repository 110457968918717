import { CccLink, isInSpa } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { preFetchOrGetPageUrl } from 'public/src/services/lazyComponent/lazy-hydrate/pre-fetch/utils.js'
import { metricCccClick } from 'public/src/pages/common/business-monitor/common.js'

/**
 * functional component中用来替代mixin
 * public/src/pages/components/ccc/components/mixins/mixin.js
 * !!!【这里并没有处理mixin中provide的isFirstPage变量，需单独处理isFirstPage】!!!
 * @param {*} param0 
 * @returns 
 */
export function useCommonMixin({ sceneData, propData, index: compIndex }) {
  const { componentKey, id, styleType, businessBlock } = propData
  const compCode = `${componentKey}${compIndex}${id}`

  const cccLink = new CccLink({ sceneData, propData })
  const metaData = propData?.props?.metaData || {}

  /**
   * 跳转页面
   */
  const jumpLink = ({ url, hrefType, routeType = 'push', scene = 'cccx' }) => {
    if (url.indexOf('javascript:;') > -1) return

    if(['homePage'].includes(sceneData?.pageFrom)) { // 点击监控
      metricCccClick({
        page: 'home_page',
        component_type: styleType || '',
        position: `${(compIndex + 1) || ''}`,
        business_block: businessBlock || '',
      })
    }

    if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
      if(window._gb_app_) {
        window._gb_app_?.$router?.[routeType](url)
      } else { // 兼容多页存在无_gb_app_实例case
        location.href = url
      }
    } else {
      sessionStorage.setItem('navtrack_to', 'page_campaigns')
      sessionStorage.setItem('navtrack_point', Date.now())
      location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true, scene } })
    }    
  }

  /**
   * 获取埋点信息
   * @param {*} id 
   * @param {*} config 
   * @param {*} setCode 
   * @returns 
   */
  const getAnalysisData = (id, config = {}, setCode = false) => {
    const { item, index: itemIndex = 0, ...arg } = config

    const data = {
      sceneData,
      propData,
      compIndex, // 组件所在位置
      item,
      index: itemIndex, // 当前item在组件中的位置
      ...arg,
    }

    const code =
      typeof setCode === 'boolean' && setCode
        ? compCode
        : typeof setCode === 'string'
          ? setCode
          : ''

    return { id, code, prefix: sceneData.pageFrom, data }
  }

  /**
   * 获取商品类埋点信息
   * @param {*} id 
   * @param {*} config 
   * @returns 
   */
  const getProductAnalysis = (id, config) => {
    const { type = 'click', index, item, cate, fault, tab, flash_type, poskey, isJumpToList, name = '', compIndex = '' } = config
    const analysisData = {
      name,
      item: cate,
      index,
      product: item,
      tab: tab,        // 当前的tab
      flash_type, // ⚠️也不要有类似这样的具有特殊类型特定组件的字段
      poskey,
      // ⚠️不要扩展类似下面两个变量的类型，应该新开一个字段统一维护
      isJumpToList, // 是否跳到列表(闪购、一口价列表等)
      fault, // 商品是否是容错请求之后的商品
    }

    if (typeof compIndex === 'number') Object.assign(analysisData, { compIndex })

    const setCode = type === 'expose' ? `${this.code}_product` : ''

    return getAnalysisData(id, analysisData, setCode)
  }

  return {
    cccLink,
    metaData,
    jumpLink,
    code: compCode,
    getAnalysisData,
    getProductAnalysis
  }
}
