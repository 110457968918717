<template>
  <div
    v-if="isInvoke"
    ref="invokeAppRef"
    class="invoke-app"
    @click="invokeApp"
  >
    <slot></slot>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { parseQueryString, stringifyQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import Clipboard from 'clipboard'

const isBrowser = typeof window !== 'undefined'
const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
const isAndroid = UA && UA.indexOf('android') > 0
const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)

const { WEB_CLIENT } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

/**
 * 唤起App组件
 * prop:
 * isInvoke 开关
 * urlPath 唤起App h5路径，例如 /campaigns/ccc-id
 * deeplinkSource 自定义的deeplink链接
 * isDirectStore 是否直接跳转到应用商店
 * isDeeplinkStyleString 是否根据活动id获取格式文本，不再使用短链(只适用于IOS新人价活动)
 * emit:
 * invoke-app 唤起app事件
 * invoke-store 唤起store事件
 */
export default defineComponent({
  emits: ['invoke-app', 'invoke-store'],
  props: {
    isInvoke: {
      type: Boolean,
      default: true,
    },
    urlPath: {
      type: String,
      default: '',
    },
    deeplinkSource: {
      type: [String, Number],
      default: '',
    },
    isDirectStore: {
      type: Boolean,
      default: false,
    },
    isDeeplinkStyleString: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      callApp: null,
      copyHandle: null,
    }
  },
  computed: {
    // 跳转落地页
    invitationUrl() {
      let shareUrl = `https://api-${WEB_CLIENT}.${WEB_CLIENT}.com/h5`
      let query = parseQueryString(location.search)
      const url = `${shareUrl}${this.urlPath}?${stringifyQueryString({ queryObj: query })}`
      return url
    },
    // deeplink链接
    deeplink({ invitationUrl }) {
      const link = this.deeplinkSource || `${WEB_CLIENT}link://applink/pushtoweb2?data=${encodeURIComponent(JSON.stringify({ url: invitationUrl }))}`
      return link
    },
  },
  beforeUnmount() {
    this.callApp?.destroy()
  },
  async mounted() {
    if (!this.isInvoke) return

    this.callApp = new this.$callApp()
    if (isIOS) {
      this.initClipboard()
    }
  },
  methods: {
    isSafari(){
      const ua = navigator.userAgent.toLowerCase()
      return (
        ua.indexOf('applewebkit') > -1
        && ua.indexOf('mobile') > -1
        && ua.indexOf('safari') > -1
        && ua.indexOf('linux') === -1
        && ua.indexOf('android') === -1
        && ua.indexOf('chrome') === -1
        && ua.indexOf('ios') === -1
        && ua.indexOf('browser') === -1)
    },
    asyncTime(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
    // 调用接口同步短链与长链的绑定关系
    async syncShortLink() {
      const data = this.callApp.getShortData({ deeplink: this.deeplink })
      await this.callApp.saveRemoteSync(data)
      return data
    },
    // 获取格式文本
    async getStyleString() {
      return await schttp({
        url: '/api/common/styleString/get',
        method: 'post',
        data: {
          activityId: 1314, // 分配的活动id，目前先写死
        },
      })
    },
    // 初始化剪贴板
    async initClipboard() {
      if (this.isDeeplinkStyleString && isIOS) {
        try { this.copyHandle?.destroy() } catch (error) { return }
        // 请求活动id获取格式文本，放入剪贴板
        const { code, info } = await this.getStyleString().catch(() => {})
        if (code === '0' && info?.styleString) {
          this.onClipboard(this.$refs.invokeAppRef, info.styleString)
        }
        return
      }
      this.callApp = new this.$callApp()
      this.callApp.bindClick(this.$refs.invokeAppRef)
    },
    onClipboard(dom, txt) {
      this.copyHandle = new Clipboard(dom, {
        text: () => txt,
      })
      this.copyHandle.on('success', () => {
        console.log('复制成功')
      })
      this.copyHandle.on('error', () => {
        console.log('复制失败')
      })
    },
    async invokeApp() {
      if (!this.isDirectStore) {
        location.href = this.deeplink
        await this.asyncTime(3000)
        // 成功唤起app
        if (document.visibilityState !== 'visible') {
          this.$emit('invoke-app')
          return
        }
      }

      let shortLink = ''
      if (!this.isDeeplinkStyleString || isAndroid) {
        // 绑定短链与长链的关系
        const { key } = await this.syncShortLink()
        shortLink = key
      }

      // 唤起应用商店
      const appDownloadUrl = this.callApp.appDownloadUrl
      this.$emit('invoke-store')
      // android
      if (isAndroid) {
        const referrerParam = encodeURIComponent(`utm_shein_onelink=${shortLink}`)
        location.href = appDownloadUrl.indexOf('?') > -1 ? `${appDownloadUrl}&referrer=${referrerParam}` : `${appDownloadUrl}?referrer=${referrerParam}`
        return
      }

      // ios
      if (this.isDeeplinkStyleString) {
        await this.asyncTime(50) // 避免复制失败
      }
      location.href = this.callApp.appDownloadUrl
    },
  },
})
</script>

